import React from "react";
import { Link } from "gatsby";
import { Layout, Menu, Row, Col, Button, Popover } from "antd";
import ContactUs from "../../pages/contact";

import logo from "../../images/dayviewer-logo-dark.svg";
import { MenuOutlined } from "@ant-design/icons";

const { Header } = Layout;

const CommonHeader = () => {
  return (
    <Header>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          // position: "fixed",
          width: "100%",
          zIndex: 1,
          // backgroundColor: "#222429",
          // color: "#ffffff"
        }}
      >
        <Link to={`/`}>
          <img
            className="header-logo"
            src={logo}
            alt="DayViewer Online Calendar Planner"
            title="DayViewer Online Calendar Planner"
            width="200"
          />
        </Link>
        <div>
          <Col lg={24} xs={0}>
            <div style={{ display: "flex" }}>
              <Menu
                // theme="dark"
                disabledOverflow={true}
                mode="horizontal"
                style={{
                  lineHeight: "64px",
                  // backgroundColor: "#222429",
                  color: "#ffffff",
                }}
              >
                <Menu.Item key="1">
                  <Link to={`/business/`}>Business</Link>
                </Menu.Item>
                {/* <Menu.Item key="2">
                    <Link to={`/features/`}>Features</Link>
                  </Menu.Item> */}
                <Menu.Item key="3">
                  <Link to={`/use-cases/`}>Use Cases</Link>
                </Menu.Item>
                <Menu.Item key="4">
                  <Link to={`/plans/`}>Plans</Link>
                </Menu.Item>
                <Menu.Item key="5">
                  <Link to={`/guide/`}>Guide</Link>
                </Menu.Item>
              </Menu>

              <Button className="homepage-signup-btn" style={{ marginTop: 5 }}>
                <a
                  href="#"
                  onClick={() =>
                    window.open("https://app.dayviewer.com/auth/login", "_self")
                  }
                >
                  Sign In/Up
                </a>
              </Button>
            </div>
          </Col>
          <Col xs={24} lg={0}>
            <Button className="homepage-signup-btn">
              <a
                href="#"
                onClick={() =>
                  window.open("https://app.dayviewer.com/auth/login", "_self")
                }
              >
                Sign In/Up
              </a>
            </Button>
            <Popover
              trigger="click"
              placement="bottomRight"
              content={
                <Menu
                  theme="dark"
                  mode="vertical"
                  style={{
                    lineHeight: "64px",
                  }}
                >
                  <Menu.Item key="0">
                    <a
                      href="#"
                      onClick={() =>
                        window.open(
                          "https://app.dayviewer.com/auth/login",
                          "_self"
                        )
                      }
                    >
                      Sign In/Up
                    </a>
                  </Menu.Item>
                  <Menu.Item key="1">
                    <Link to={`/business/`}>Business</Link>
                  </Menu.Item>
                  {/* <Menu.Item key="2">
                      <Link to={`/features/`}>Features</Link>
                    </Menu.Item> */}
                  <Menu.Item key="3">
                    <Link to={`/plans/`}>Plans</Link>
                  </Menu.Item>
                  <Menu.Item key="4">
                    <Link to={`/use-cases/`}>Use Cases</Link>
                  </Menu.Item>
                  <Menu.Item key="5">
                    <Link to={`/guide/`}>Guide</Link>
                  </Menu.Item>
                  <Menu.Item key="6">
                    <Link to={`/about/`}>About</Link>
                  </Menu.Item>
                  <Menu.Item key="7">
                    <Link to={`/articles/`}>Articles</Link>
                  </Menu.Item>
                  <Menu.Item key="8">
                    <ContactUs />
                  </Menu.Item>
                </Menu>
              }
            >
              <MenuOutlined
                style={{ fontSize: 24, marginRight: 10, marginLeft: 10 }}
              />
            </Popover>
          </Col>
        </div>
      </div>
    </Header>
  );
};

export default CommonHeader;
