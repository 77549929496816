import React from "react";
import { Link, Script } from "gatsby";
import { Layout, Menu } from "antd";

import ContactUs from "../../pages/contact";

const { Footer } = Layout;

class CommonFooter extends React.Component {
  render() {
    const currentYear = new Date().getFullYear();

    return (
      <Footer
        style={{
          background: "#001529",
          color: "#ffffffb3",
          // height: "80px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div />

          <div style={{ textAlign: "right" }}>
            <Menu
              theme="dark"
              mode="horizontal"
              style={{
                backgroundColor: "inherit",
              }}
            >
              <Menu.Item key="1">
                <Link to={`/about/`}>About</Link>
              </Menu.Item>
              <Menu.Item key="2">
                <Link to={`/articles/`}>Articles</Link>
              </Menu.Item>
              <Menu.Item key="3">
                <a href="https://blog.dayviewer.com" target="_blank">
                  Blog
                </a>
              </Menu.Item>
              <Menu.Item key="4">
                <Link to={`/features/`}>Features</Link>
              </Menu.Item>

              <Menu.Item key="0">
                <ContactUs />
              </Menu.Item>
            </Menu>
          </div>
        </div>

        <div style={{ textAlign: "center", background: "#001529" }}>
          &copy; DayViewer Ltd {currentYear} {"  "}
          <Link to={`/about-content/privacy/`}>Privacy</Link> |{" "}
          <Link to={`/about-content/terms/`}>Terms</Link>
        </div>
      </Footer>
    );
  }
}

export default CommonFooter;
